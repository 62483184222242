import React, { useEffect, useState } from 'react';
import axios from 'axios';
import L from 'leaflet';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty, find } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
  Marker,
} from 'components/atoms';
import {
  FormControl,
  MultipleSelect,
  Map,
  Spinner,
} from 'components/molecules';
import { AddressSuggestion } from 'components/organisms';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';
import {
  getBranchOffice,
  patchBranchOffice,
  postBranchOffice,
  getCities,
  getDistricts,
  getOrderProtocols,
  getTrackings,
  getCoverages,
  getTimeWindows,
  getVehicleTypes,
  getIntegrations,
} from 'services';
import { optionFormatter } from 'util/functions';
import { EditProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
  address: ['mandatory'],
  city: ['mandatory'],
  reference_code: ['mandatory'],
  // tracking_configuration: ['mandatory'],
  contact_email: ['email'],
  assignation_radius: ['number'],
};

const EditOffice: React.FC<EditProps> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useTypedSelector(state => state.user);
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  const [form, onFormChange, validate, errors, reset, updateForm] = useForm(
    {
      name: data ? data.name : '',
      address: '',
      city: data && data.city ? data.city.id : '',
      coverages: data && data.coverages ? data.coverages : [],
      reference_code: data ? data.reference_code : '',
      order_protocol: '',
      tracking_configuration: '',
      coordinates_latitude: '',
      coordinates_longitude: '',

      contact_person: '',
      contact_phone: '',
      contact_email: '',
      contact_id: '',
      phone_country: 'pe', // should be dynamic according to the country where it has logged
      address_detail: '',
      special_instructions: '',
      district: '',
      assignation_radius: 1,
      provider: '',
    },
    validationState
  );
  // API calls
  const [providersResponse, fetchProviders, loadingProviders] = useRequest(
    getIntegrations,
    []
  );
  const [protocols, fetchProtocols, loadingProtocols] = useRequest(
    getOrderProtocols,
    []
  );
  const [trackings, fetchTrackings, loadingTrackings] = useRequest(
    getTrackings,
    []
  );
  const [office, fetchBranchOffice, loadingOffice] = useRequest(
    getBranchOffice
  );
  const [cities, fetchCities, loadingCities] = useRequest(getCities, []);
  const [districts, fetchDistricts, loadingDistricts] = useRequest(
    getDistricts,
    []
  );
  const [coverages, fetchCoverages, loadingCoverages] = useRequest(
    getCoverages,
    []
  );
  const [timeWindows, fetchTimeWindows, loadingTimeWindows] = useRequest(
    getTimeWindows,
    []
  );
  const [vehicleTypes, fetchVehicleTypes, loadingVehicleTypes] = useRequest(
    getVehicleTypes,
    []
  );
  const [
    updatedOffice,
    updateOffice,
    loadingUpdate,
    ,
    errorUpdate,
  ] = useRequest(patchBranchOffice);
  const [
    createdOffice,
    createOffice,
    loadingCreate,
    ,
    errorCreate,
  ] = useRequest(postBranchOffice);
  // Values
  const [providerOptions, setProviderOptions] = useState([]);
  const [trackingOptions, setTrackingOptions] = useState([]);
  const [protocolOptions, setProtocolOptions] = useState([]);
  const [coverageOptions, setCoverageOptions] = useState([]);
  const [selectedCoverages, setSelectedCoverages] = useState([]);
  const [timeWindowsOptions, setTimeWindowsOptions] = useState([]);
  const [selectedTimeWindows, setSelectedTimeWindows] = useState([]);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [bounds, setBounds] = useState();

  useEffect(() => {
    const filteredAvailable = Object.values(providersResponse).filter(
      (provider: any) => provider.is_active
    );

    setProviderOptions(
      optionFormatter(filteredAvailable, {
        id: option => option.code,
        name: option => option.name,
      })
    );
  }, [providersResponse]);

  useEffect(() => {
    if (data) {
      fetchBranchOffice(data.id);
    }
    fetchCities({
      page_size: 0,
    });
    fetchProtocols({
      page_size: 0,
    });
    fetchTrackings({
      page_size: 0,
    });
    fetchCoverages();
    fetchTimeWindows({
      page_size: 0,
    });
    fetchVehicleTypes({
      page_size: 0,
    });
    fetchProviders();
  }, [
    fetchBranchOffice,
    fetchCities,
    fetchProtocols,
    fetchCoverages,
    fetchTimeWindows,
    fetchVehicleTypes,
    fetchProviders,
  ]);

  const handleAddressUpdate = event => {
    const position = {
      latitude: event.geometry.location.lat,
      longitude: event.geometry.location.lng,
    };
    updateForm({
      address: event.description || event.formatted_address,
      coordinates_latitude: position.latitude,
      coordinates_longitude: position.longitude,
    });
  };

  const handleMarkerDrag = e => {
    const coords = e.target.getLatLng();
    // const params = {
    //   key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    //   latlng: `${coords.lat},${coords.lng}`,
    // };
    const params = {
      key: 'AIzaSyCj4QlWXbL-wUd6slh5N2fMlsFsNOAbKxA',
      latlng: `${coords.lat},${coords.lng}`,
    };
    axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json`,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(results => {
        if (results.data.results.length > 0) {
          handleAddressUpdate(results.data.results[0]);
        } else {
          handleAddressUpdate(results.data.results[0]);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error con la dirección', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (form.coordinates_latitude && form.coordinates_longitude) {
      const newBounds = L.latLngBounds([
        { lat: form.coordinates_latitude, lng: form.coordinates_longitude },
      ]);
      setBounds(newBounds);

      // const params = {
      //   key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
      //   latlng: `${form.coordinates_latitude},${form.coordinates_longitude}`,
      // };
      const params = {
        key: 'AIzaSyCj4QlWXbL-wUd6slh5N2fMlsFsNOAbKxA',
        latlng: `${form.coordinates_latitude},${form.coordinates_longitude}`,
      };
      axios({
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/geocode/json`,
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(results => {
          if (results.data.results.length > 0) {
            handleAddressUpdate(results.data.results[0]);
          } else {
            handleAddressUpdate(results.data.results[0]);
          }
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error con la dirección', {
            variant: 'error',
          });
        });
    }
  }, [form.coordinates_latitude, form.coordinates_longitude]);

  useEffect(() => {
    if (coverages.length > 0) {
      setCoverageOptions(optionFormatter(coverages));
    }
  }, [coverages]);

  useEffect(() => {
    if (timeWindows.length > 0) {
      setTimeWindowsOptions(
        optionFormatter(timeWindows, {
          id: option => option.id,
          name: option => `${option.start_hour} : ${option.end_hour}`,
        })
      );
    }
  }, [timeWindows]);

  useEffect(() => {
    if (vehicleTypes.length > 0) {
      setVehicleTypesOptions(optionFormatter(vehicleTypes));
    }
  }, [vehicleTypes]);

  useEffect(() => {
    if (!isEmpty(office)) {
      setSelectedCoverages(optionFormatter(office.coverages));
      setSelectedTimeWindows(
        optionFormatter(office.time_windows, {
          id: option => option.id,
          name: option => option.code,
        })
      );
      setSelectedVehicleTypes(optionFormatter(office.vehicle_types));

      updateForm({
        address: office.address === 'address' ? '' : office.address,
        coordinates_latitude:
          office.coordinates && office.coordinates.latitude
            ? office.coordinates.latitude
            : '',
        coordinates_longitude:
          office.coordinates && office.coordinates.longitude
            ? office.coordinates.longitude
            : '',
        order_protocol: office.order_protocol
          ? office.order_protocol.id.toString()
          : '',
        tracking_configuration: office.tracking_configuration
          ? office.tracking_configuration.id.toString()
          : '',

        contact_person: office.contact_person,
        contact_phone: office.contact_phone,
        contact_email: office.contact_email,
        contact_id: office.contact_id,
        address_detail: office.address_detail,
        special_instructions: office.special_instructions,
        district: office.district ? office.district.id : '',
        assignation_radius: office.assignation_radius,
        provider: office.provider ? office.provider.service_type : '',
      });
    }
  }, [office]);

  useEffect(() => {
    if (form.city) {
      fetchDistricts({
        page_size: 0,
        city__id: form.city,
      });
    }
  }, [form.city]);

  useEffect(() => {
    if (protocols.length > 0) {
      setProtocolOptions(optionFormatter(protocols));
    }
  }, [protocols]);

  useEffect(() => {
    if (trackings.length > 0) {
      setTrackingOptions(optionFormatter(trackings));
    }
  }, [trackings]);

  useEffect(() => {
    if (!isEmpty(createdOffice)) {
      enqueueSnackbar('La sucursal fue creada correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdOffice, enqueueSnackbar, onCreate, reset]);

  useEffect(() => {
    if (!isEmpty(updatedOffice)) {
      enqueueSnackbar('Se actualizó la sucursal correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedOffice, enqueueSnackbar, onCreate]);

  useEffect(() => {
    if (!isEmpty(errorCreate)) {
      if (errorCreate.data.errors) {
        errorCreate.data.errors.forEach(error => {
          if (
            error.reference_code &&
            error.reference_code[0] ===
              'Branch office reference code already exists.'
          ) {
            enqueueSnackbar('El código ya se está usando en otra sucursal', {
              variant: 'error',
            });
          }
        });
      }
    }
  }, [errorCreate]);

  useEffect(() => {
    if (!isEmpty(errorUpdate)) {
      if (errorUpdate.data.errors) {
        errorUpdate.data.errors.forEach(error => {
          if (
            error.reference_code &&
            error.reference_code[0] ===
              'Branch office reference code already exists.'
          ) {
            enqueueSnackbar('El código ya se está usando en otra sucursal', {
              variant: 'error',
            });
          }
        });
      }
    }
  }, [errorUpdate]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        address: form.address,
        city: form.city,
        coverages: selectedCoverages.map(coverage => coverage.id),
        time_windows: selectedTimeWindows.map(time => time.id),
        vehicle_types: selectedVehicleTypes.map(vehicle => vehicle.id),
        reference_code: form.reference_code,
        organization: selectedOrganization.id,
        order_protocol_id: form.order_protocol,
        tracking_configuration_id: form.tracking_configuration,
        coordinates: form.coordinates_latitude &&
          form.coordinates_longitude && {
            latitude: form.coordinates_latitude,
            longitude: form.coordinates_longitude,
          },

        contact_person: form.contact_person,
        contact_phone: form.contact_phone,
        contact_email: form.contact_email,
        contact_id: form.contact_id,
        address_detail: form.address_detail,
        special_instructions: form.special_instructions,
        district: form.district,
        assignation_radius: form.assignation_radius,
        provider: form.provider,
      };

      if (data) {
        updateOffice(body, data.id);
      } else {
        createOffice(body);
      }
    }
  };

  return (
    <FlexContainer container>
      <FlexContainer
        direction='column'
        width='50%'
        height='100%'
        padding='0 20px 0 0'
      >
        <CloseIcon onClick={() => onClose()}>
          <Icon
            icon='cancel-icon'
            color={THEME.colors.placeholderColor}
            size={25}
          />
        </CloseIcon>
        <Typography fontSize={18} margin='10px 0 0'>
          {data ? data.name : 'Creación de Sucursal'}
        </Typography>
        <Divider orientation='horizontal' margin='15px 0 30px' />
        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer container alignItems='center' margin='0 0 30px'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
              >
                Información básica
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Nombre de la sucursal'
                  placeholder='Escribe el nombre de la sucursal'
                  onChange={value => onFormChange(value, 'name')}
                  value={form.name}
                  margin='0 0 20px'
                  error={errors.name}
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Código'
                  placeholder='Escribe el código'
                  onChange={value => onFormChange(value, 'reference_code')}
                  value={form.reference_code}
                  margin='0 0 20px'
                  error={errors.reference_code}
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressSuggestion
                  label='Dirección'
                  placeholder='Escribe la dirección'
                  onChange={() => {}}
                  onSelect={async value => {
                    let updateValues;

                    if (value.place_id) {
                      // if is google maps autocomplete service
                      const geocoder = new window.google.maps.Geocoder();
                      updateValues = await geocoder
                        .geocode({ placeId: value.place_id })
                        .then(({ results }) => {
                          return (updateValues = {
                            destiny: value.title,
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                          });
                        });
                    } else {
                      updateValues = {
                        destiny: updateValues.destiny,
                        latitude: value.geometry.location.lat,
                        longitude: value.geometry.location.lng,
                      };
                    }
                    updateForm({
                      address: updateValues.destiny,
                      coordinates_latitude: updateValues.latitude,
                      coordinates_longitude: updateValues.longitude,
                    });
                  }}
                  value={form.address}
                  margin='0 0 20px'
                  error={errors.address}
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Latitud'
                  placeholder='Escribe coordenada'
                  onChange={value =>
                    onFormChange(value, 'coordinates_latitude')
                  }
                  value={form.coordinates_latitude}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  // optional
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Longitud'
                  placeholder='Escribe coordenada'
                  onChange={value =>
                    onFormChange(value, 'coordinates_longitude')
                  }
                  value={form.coordinates_longitude}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  // optional
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control='select'
                  label='Ciudad'
                  placeholder='Selecciona ciudad'
                  onChange={value => onFormChange(value, 'city')}
                  value={form.city}
                  options={cities}
                  margin='0 0 20px'
                  error={errors.city}
                  width='100%'
                  disabled={loadingCreate || loadingUpdate || loadingCities}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control='select'
                  label='Distrito'
                  placeholder='Selecciona un distrito'
                  onChange={value => onFormChange(value, 'district')}
                  value={form.district}
                  options={districts}
                  margin='0 0 20px'
                  width='100%'
                  disabled={
                    loadingCreate ||
                    loadingUpdate ||
                    loadingDistricts ||
                    !form.city
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Detalles de la dirección'
                  placeholder='Escribe detalles'
                  onChange={value => onFormChange(value, 'address_detail')}
                  value={form.address_detail}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Instrucciones especiales'
                  placeholder='Escribe instrucciones'
                  onChange={value =>
                    onFormChange(value, 'special_instructions')
                  }
                  value={form.special_instructions}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MultipleSelect
                  label='Coberturas'
                  placeholder='Seleccione las coberturas'
                  options={coverageOptions}
                  values={selectedCoverages}
                  onChange={option => {
                    const found = find(selectedCoverages, {
                      id: option.id,
                    });
                    if (found) {
                      setSelectedCoverages(
                        selectedCoverages.filter(item => item.id !== option.id)
                      );
                    } else {
                      setSelectedCoverages(selectedCoverages.concat(option));
                    }
                  }}
                  onDelete={option => {
                    setSelectedCoverages(
                      selectedCoverages.filter(item => item.id !== option.id)
                    );
                  }}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingUpdate || loadingCreate || loadingCoverages}
                />
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer container alignItems='center' margin='0 0 30px'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
              >
                Detalle del contacto
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Nombre'
                  placeholder='Escribe el nombre'
                  onChange={value => onFormChange(value, 'contact_person')}
                  value={form.contact_person}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control='phone'
                  country={form.phone_country}
                  label='Teléfono'
                  placeholder='Escribe el teléfono'
                  onChange={value => onFormChange(value, 'contact_phone')}
                  value={form.contact_phone}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Email'
                  placeholder='Escribe el email'
                  onChange={value => onFormChange(value, 'contact_email')}
                  value={form.contact_email}
                  margin='0 0 20px'
                  error={errors.contact_email}
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='N° de documento'
                  placeholder='Escribe el documento'
                  onChange={value => onFormChange(value, 'contact_id')}
                  value={form.contact_id}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                  optional
                />
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>

        {user.permissions.includes('core.worker.list') &&
          user.permissions.includes('core.vehicle.list') &&
          user.permissions.includes('core.provider.list') && (
            <FlexContainer
              container
              direction='column'
              padding='30px 30px'
              margin='0 0 30px'
              borderRadius='10px'
              backgroundColor={THEME.colors.backgroundColor}
              borderColor={THEME.colors.borderColor}
            >
              <FlexContainer container alignItems='center' margin='0 0 30px'>
                <FlexContainer
                  container
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography
                    color={THEME.colors.primary}
                    fontWeight={500}
                    fontSize={17}
                  >
                    Configuración de flota propia
                  </Typography>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer container direction='column'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='select'
                      label='Protocolo'
                      placeholder='Selecciona el protocolo'
                      onChange={value => onFormChange(value, 'order_protocol')}
                      value={form.order_protocol}
                      margin='0 0 20px'
                      error={errors.order_protocol}
                      width='100%'
                      options={protocolOptions}
                      disabled={
                        loadingCreate ||
                        loadingUpdate ||
                        loadingProtocols ||
                        loadingOffice
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='select'
                      label='Tracking'
                      placeholder='Selecciona el tracking'
                      onChange={value =>
                        onFormChange(value, 'tracking_configuration')
                      }
                      value={form.tracking_configuration}
                      margin='0 0 20px'
                      error={errors.tracking_configuration}
                      width='100%'
                      options={trackingOptions}
                      disabled={
                        loadingCreate ||
                        loadingUpdate ||
                        loadingTrackings ||
                        loadingOffice
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MultipleSelect
                      label='Ventanas Horarias'
                      placeholder='Seleccione las ventanas horarias'
                      options={timeWindowsOptions}
                      values={selectedTimeWindows}
                      onChange={option => {
                        const found = find(selectedTimeWindows, {
                          id: option.id,
                        });
                        if (found) {
                          setSelectedTimeWindows(
                            selectedTimeWindows.filter(
                              item => item.id !== option.id
                            )
                          );
                        } else {
                          setSelectedTimeWindows(
                            selectedTimeWindows.concat(option)
                          );
                        }
                      }}
                      onDelete={option => {
                        setSelectedTimeWindows(
                          selectedTimeWindows.filter(
                            item => item.id !== option.id
                          )
                        );
                      }}
                      margin='0 0 20px'
                      width='100%'
                      disabled={
                        loadingUpdate || loadingCreate || loadingTimeWindows
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MultipleSelect
                      label='Tipos de Vehículos'
                      placeholder='Seleccione los tipos de vehículos'
                      options={vehicleTypesOptions}
                      values={selectedVehicleTypes}
                      onChange={option => {
                        const found = find(selectedVehicleTypes, {
                          id: option.id,
                        });
                        if (found) {
                          setSelectedVehicleTypes(
                            selectedVehicleTypes.filter(
                              item => item.id !== option.id
                            )
                          );
                        } else {
                          setSelectedVehicleTypes(
                            selectedVehicleTypes.concat(option)
                          );
                        }
                      }}
                      onDelete={option => {
                        setSelectedVehicleTypes(
                          selectedVehicleTypes.filter(
                            item => item.id !== option.id
                          )
                        );
                      }}
                      margin='0 0 20px'
                      width='100%'
                      disabled={
                        loadingUpdate || loadingCreate || loadingVehicleTypes
                      }
                    />
                  </Grid>
                </Grid>

                {data && (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <FormControl
                        label='Radio de Asignación (m)'
                        onChange={value =>
                          onFormChange(value, 'assignation_radius')
                        }
                        value={form.assignation_radius}
                        margin='0 0 20px'
                        error={errors.assignation_radius}
                        width='100%'
                        disabled={loadingCreate || loadingUpdate}
                      />
                    </Grid>
                  </Grid>
                )}
              </FlexContainer>
            </FlexContainer>
          )}

        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer container alignItems='center' margin='0 0 30px'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
              >
                Configuración de Operador Logístico
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormControl
                  control='select'
                  label='Operador Logístico'
                  placeholder='Selecciona el operador logístico'
                  onChange={value => onFormChange(value, 'provider')}
                  value={form.provider}
                  margin='0 0 20px'
                  error={errors.provider}
                  width='100%'
                  options={providerOptions}
                  disabled={
                    loadingCreate ||
                    loadingUpdate ||
                    loadingProtocols ||
                    loadingOffice ||
                    loadingProviders
                  }
                />
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>

        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
        >
          {(loadingCreate || loadingUpdate) && (
            <Spinner
              height='15px'
              spinnerSize={20}
              colorSecondary
              margin='0 8px 0 0'
            />
          )}
          {data ? 'Guardar' : 'Crear'}
        </Button>
      </FlexContainer>

      <FlexContainer width='50%' height='100%' margin='0 0 0 5px'>
        <Map zoom={13} position={[-12.1166362, -77.0138613]} bounds={bounds}>
          {form.coordinates_latitude && form.coordinates_longitude && (
            <Marker
              position={[form.coordinates_latitude, form.coordinates_longitude]}
              icon='build'
              draggable
              onDragEnd={e => handleMarkerDrag(e)}
            />
          )}
        </Map>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditOffice;
