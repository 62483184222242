import React, { useEffect, useState } from 'react';
import axios from 'axios';
import L from 'leaflet';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Typography,
  Icon,
  Switch,
  Marker,
} from 'components/atoms';
import { FormControl, Map, Spinner } from 'components/molecules';
import { AddressSuggestion } from 'components/organisms';
import THEME from 'util/styledTheme';
import { postClient, patchClient, getCities, getDistricts } from 'services';
import { EditTypes } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
};

const EditClient: React.FC<EditTypes> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset, updateForm] = useForm(
    {
      name: data ? data.name : '',
      tax_id: data ? data.tax_id : '',
      address: data ? data.address : '',
      is_active: data ? data.is_active : true,
      contact_person: data ? data.contact_person : '',
      contact_phone: data ? data.contact_phone : '',
      contact_email: data ? data.contact_email : '',
      contact_id: data ? data.contact_id : '',
      coordinates: data && data.coordinates ? data.coordinates : null,
      address_detail: data ? data.address_detail : '',
      special_instructions: data ? data.special_instructions : '',
      city: data && data.city ? data.city : '',
      district: data && data.district ? data.district : '',
    },
    validationState
  );
  // Values
  const [resetAddress, setResetAddress] = useState<number>(1);
  const [bounds, setBounds] = useState();

  // API calls
  const [cities, fetchCities, loadingCities] = useRequest(getCities, []);
  const [districts, fetchDistricts, loadingDistricts] = useRequest(
    getDistricts,
    []
  );
  const [updatedClient, updateClient, loadingUpdate] = useRequest(patchClient);
  const [createdClient, createClient, loadingCreate] = useRequest(postClient);

  useEffect(() => {
    fetchCities({
      page_size: 0,
    });
  }, [fetchCities]);

  useEffect(() => {
    if (form.city) {
      fetchDistricts({
        page_size: 0,
        city__id: form.city,
      });
    }
  }, [form.city]);

  useEffect(() => {
    if (!isEmpty(createdClient)) {
      enqueueSnackbar('El cliente fue creado satisfactoriamente', {
        variant: 'success',
      });
      onCreate();
      reset();
      setResetAddress(resetAddress + 1);
    }
  }, [createdClient, enqueueSnackbar, reset]);

  useEffect(() => {
    if (!isEmpty(updatedClient)) {
      enqueueSnackbar('Se actualizó el cliente correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedClient, enqueueSnackbar]);

  useEffect(() => {
    if (form.coordinates) {
      const newBounds = L.latLngBounds([
        { lat: form.coordinates.latitude, lng: form.coordinates.longitude },
      ]);
      setBounds(newBounds);
    }
  }, [form.coordinates]);

  const validateForm = () => {
    if (validate()) {
      if (form.address && !form.coordinates) {
        enqueueSnackbar(
          'Hubo un problema con la dirección, por favor ingrese una nueva.',
          {
            variant: 'error',
          }
        );
        return;
      }
      const client = {
        name: form.name,
        tax_id: form.tax_id,
        address: form.address,
        is_active: form.is_active,
        contact_person: form.contact_person,
        contact_phone: form.contact_phone,
        contact_email: form.contact_email,
        contact_id: form.contact_id,
        address_detail: form.address_detail,
        special_instructions: form.special_instructions,
        city: form.city,
        district: form.district,
        coordinates: form.coordinates,
      };

      if (data) {
        updateClient(client, data.id);
      } else {
        createClient(client);
      }
    }
  };

  const handleAddressUpdate = event => {
    const position = {
      latitude: event.geometry.location.lat,
      longitude: event.geometry.location.lng,
    };
    updateForm({
      address: event.description || event.formatted_address,
      coordinates: position,
    });
  };

  const handleMarkerDrag = e => {
    const coords = e.target.getLatLng();
    // const params = {
    //   key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    //   latlng: `${coords.lat},${coords.lng}`,
    // };
    const params = {
      key: 'AIzaSyCj4QlWXbL-wUd6slh5N2fMlsFsNOAbKxA',
      latlng: `${coords.lat},${coords.lng}`,
    };
    axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json`,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(results => {
        if (results.data.results.length > 0) {
          handleAddressUpdate(results.data.results[0]);
        } else {
          handleAddressUpdate(results.data.results[0]);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error con la dirección', {
          variant: 'error',
        });
      });
  };

  return (
    <FlexContainer container>
      <FlexContainer
        direction='column'
        width='50%'
        height='100%'
        padding='0 20px 0 0'
      >
        <CloseIcon onClick={() => onClose()}>
          <Icon
            icon='cancel-icon'
            color={THEME.colors.placeholderColor}
            size={25}
          />
        </CloseIcon>
        <Typography fontSize={18} margin='10px 0 0'>
          {data ? 'Editar Cliente' : 'Creación de Cliente'}
        </Typography>
        <Divider orientation='horizontal' margin='15px 0 30px' />

        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer container alignItems='center' margin='0 0 30px'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
              >
                Información básica
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Nombre del cliente'
                  placeholder='Escriba el nombre del cliente'
                  onChange={value => onFormChange(value, 'name')}
                  value={form.name}
                  margin='0 0 20px'
                  error={errors.name}
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='RUC'
                  placeholder='Escriba el RUC del cliente'
                  onChange={value => onFormChange(value, 'tax_id')}
                  value={form.tax_id}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FlexContainer alignItems='center' margin='0 0 20px 0'>
                  <Typography fontSize={12}>Activo</Typography>
                  <Switch
                    color='primary'
                    checked={form.is_active}
                    onChange={event =>
                      onFormChange(event.target.checked, 'is_active')
                    }
                  />
                </FlexContainer>
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer container alignItems='center' margin='0 0 30px'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
              >
                Dirección principal
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Nombre contacto'
                  placeholder='Escribe el nombre'
                  onChange={value => onFormChange(value, 'contact_person')}
                  value={form.contact_person}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Teléfono'
                  placeholder='Escribe el teléfono'
                  onChange={value => onFormChange(value, 'contact_phone')}
                  value={form.contact_phone}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Email'
                  placeholder='Escribe el email'
                  onChange={value => onFormChange(value, 'contact_email')}
                  value={form.contact_email}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='N° de documento'
                  placeholder='Escribe el documento'
                  onChange={value => onFormChange(value, 'contact_id')}
                  value={form.contact_id}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control='select'
                  label='Ciudad'
                  placeholder='Selecciona ciudad'
                  onChange={value => onFormChange(value, 'city')}
                  value={form.city}
                  options={cities}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate || loadingCities}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  control='select'
                  label='Distrito'
                  placeholder='Selecciona un distrito'
                  onChange={value => onFormChange(value, 'district')}
                  value={form.district}
                  options={districts}
                  margin='0 0 20px'
                  width='100%'
                  disabled={
                    loadingCreate ||
                    loadingUpdate ||
                    loadingDistricts ||
                    !form.city
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressSuggestion
                  label='Dirección'
                  placeholder='Escribe la dirección'
                  onChange={() => {
                    updateForm({
                      address: '',
                      coordinates: null,
                    });
                  }}
                  onSelect={async value => {
                    let updateValues;

                    if (value.place_id) {
                      // if is google maps autocomplete service
                      const geocoder = new window.google.maps.Geocoder();
                      updateValues = await geocoder
                        .geocode({ placeId: value.place_id })
                        .then(({ results }) => {
                          return (updateValues = {
                            destiny: value.title,
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                          });
                        });
                    } else {
                      updateValues = {
                        destiny: value.description || value.formatted_address,
                        latitude: value.geometry.location.lat,
                        longitude: value.geometry.location.lng,
                      };
                    }
                    updateForm({
                      address: updateValues.destiny,
                      coordinates: {
                        latitude: updateValues.latitude,
                        longitude: updateValues.longitude,
                      },
                    });
                  }}
                  value={form.address}
                  margin='0 0 20px'
                  disabled={loadingCreate || loadingUpdate}
                  reset={resetAddress}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Detalles de la dirección'
                  placeholder='Escribe el código'
                  onChange={value => onFormChange(value, 'address_detail')}
                  value={form.address_detail}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  label='Instrucciones especiales'
                  placeholder='Escribe el código'
                  onChange={value =>
                    onFormChange(value, 'special_instructions')
                  }
                  value={form.special_instructions}
                  margin='0 0 20px'
                  width='100%'
                  disabled={loadingCreate || loadingUpdate}
                />
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>

        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
        >
          {(loadingCreate || loadingUpdate) && (
            <Spinner
              height='15px'
              spinnerSize={20}
              colorSecondary
              margin='0 8px 0 0'
            />
          )}
          {data ? 'Guardar' : 'Crear'}
        </Button>
      </FlexContainer>

      <FlexContainer width='50%' height='100%' margin='0 0 0 5px'>
        <Map zoom={13} position={[-12.1166362, -77.0138613]} bounds={bounds}>
          {form.coordinates && (
            <Marker
              position={[form.coordinates.latitude, form.coordinates.longitude]}
              icon='build'
              draggable
              onDragEnd={e => handleMarkerDrag(e)}
            />
          )}
        </Map>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditClient;
